import {
  RATES_SET_TAB,
  RATES_SET_TEST_UNITS,
  RATES_SET_TEST_AMOUNTS,
  RATES_SET_TEST_INCOMPLETE,
  RATES_SET_DEMAND_TEST_UNITS,
  RATES_SET_DEMAND_TEST_AMOUNTS,
  RATES_SET_DEMAND_TEST_INCOMPLETE,
} from '../actions/ratesActions'
import { commonReducer } from './commonReducer'
import { grid, pagination, viewEdit } from './commonStates'

const initialState = {
  ...grid(),
  ...pagination(),
  ...viewEdit(),
  tab: 0,
  selectedOption: {
    value: 0,
    label: 'Active',
  },
}

export default (state = initialState, action) => {
  const { type, tab, testUnits, demandTestUnits, testAmount, demandTestAmount, tierGroups, demandTierGroups, } = action

  switch (type) {
  case RATES_SET_TAB:
    return Object.assign({}, state, {
      tab,
    })
  case RATES_SET_TEST_UNITS:
    state.opened.testUnits = testUnits
    return Object.assign({}, state, { opened: state.opened })
  case RATES_SET_DEMAND_TEST_UNITS:
    state.opened.demandTestUnits = demandTestUnits
    return Object.assign({}, state, { opened: state.opened })
  case RATES_SET_TEST_AMOUNTS:
    state.opened.testAmount = testAmount
    state.opened.testLineAmounts = tierGroups
    return Object.assign({}, state, { opened: state.opened })
  case RATES_SET_DEMAND_TEST_AMOUNTS:
    state.opened.demandTestAmount = demandTestAmount
    state.opened.demandTestLineAmounts = demandTierGroups
    return Object.assign({}, state, { opened: state.opened })
  case RATES_SET_TEST_INCOMPLETE:
    state.opened.testIncomplete = true
    state.opened.testLineAmounts = tierGroups
    return Object.assign({}, state, { opened: state.opened })
  case RATES_SET_DEMAND_TEST_INCOMPLETE:
    state.opened.demandTestIncomplete = true
    state.opened.demandTestLineAmounts = demandTierGroups
    return Object.assign({}, state, { opened: state.opened })
  default:
    return commonReducer('RATES', state, action)
  }
}

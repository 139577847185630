import * as request from 'request-promise-native'
import config from '../config'
import { getCommonActions } from './commonActions'
import { deepCopy } from '../utils/helperFunctions'

export const RATES_SET_TAB = 'RATES_SET_TAB'
export const REQUEST_DUPLICATE_RATES = 'REQUEST_DUPLICATE_RATES'
export const RECEIVE_DUPLICATE_RATES = 'RECEIVE_DUPLICATE_RATES'
export const ERROR_DUPLICATE_RATES = 'ERROR_DUPLICATE_RATES'
export const RATES_SET_TEST_UNITS = 'RATES_SET_TEST_UNITS'
export const RATES_SET_TEST_AMOUNTS = 'RATES_SET_TEST_AMOUNTS'
export const RATES_SET_TEST_INCOMPLETE = 'RATES_SET_TEST_INCOMPLETE'
export const RATES_SET_DEMAND_TEST_UNITS = 'RATES_SET_DEMAND_TEST_UNITS'
export const RATES_SET_DEMAND_TEST_AMOUNTS = 'RATES_SET_DEMAND_TEST_AMOUNTS'
export const RATES_SET_DEMAND_TEST_INCOMPLETE = 'RATES_SET_DEMAND_TEST_INCOMPLETE'

const requestDuplicateRates = () => {
  return {
    type: REQUEST_DUPLICATE_RATES,
  }
}

const receiveDuplicateRates = rate => {
  return {
    type: RECEIVE_DUPLICATE_RATES,
    rate,
  }
}

const errorDuplicateRates = err => {
  return {
    type: ERROR_DUPLICATE_RATES,
    err,
  }
}

export const rates = () =>
  getCommonActions('RATES', {
    mapState: state => state.rates,
    endpoint: 'rate',
    searchFields: ['number'],
    labelFields: ['number'],
  })

export const duplicateRates = ids => {
  return async (dispatch, getState) => {
    dispatch(requestDuplicateRates())
    const { loginToken } = getState().auth
    const options = {
      method: 'POST',
      uri: `${config.baseUrl}/rate/duplicate`,
      headers: {
        auth: JSON.stringify({ token: loginToken }),
      },
      body: { ids },
      json: true,
    }

    const req = await request(options)

    if (!req) return dispatch(errorDuplicateRates('There was a problem loading the rates!'))

    const { err, res } = req
    if (err) return dispatch(errorDuplicateRates(err.toString()))
    else return dispatch(receiveDuplicateRates(res))
  }
}

export const setTab = tab => {
  return {
    type: RATES_SET_TAB,
    tab,
  }
}

export const setTestUnits = testUnits => {
  return {
    type: RATES_SET_TEST_UNITS,
    testUnits,
  }
}

export const setDemandTestUnits = demandTestUnits => {
  return {
    type: RATES_SET_DEMAND_TEST_UNITS,
    demandTestUnits,
  }
}

export const setTestAmounts = (testAmount, tierGroups) => {
  return {
    type: RATES_SET_TEST_AMOUNTS,
    testAmount,
    tierGroups,
  }
}

export const setDemandTestAmounts = (demandTestAmount, demandTierGroups) => {
  return {
    type: RATES_SET_DEMAND_TEST_AMOUNTS,
    demandTestAmount,
    demandTierGroups,
  }
}

export const setTestIncomplete = tierGroups => {
  return {
    type: RATES_SET_TEST_INCOMPLETE,
    tierGroups,
  }
}

export const setDemandTestIncomplete = demandTierGroups => {
  return {
    type: RATES_SET_DEMAND_TEST_INCOMPLETE,
    demandTierGroups,
  }
}

export const calculateTestAmount = rate => {
  return async (dispatch, getState) => {
    const { opened } = getState().rates
    const units = rate.demand
      ? opened.demandTestUnits
        ? opened.demandTestUnits
        : 0
      : opened.testUnits
        ? opened.testUnits
        : 0

    const _rate = deepCopy(rate)

    calculateIndependentTiers(units, _rate.tierGroups)
    const complete = calculateDependentTiers(units, _rate.tierGroups)
    if (complete) {
      let totalAmount = _rate.tierGroups.reduce((a, b) => a + (b.totalAmount || 0), 0) + _rate.baseCharge
      if (_rate.maximum !== null && totalAmount > _rate.maximum) {
        totalAmount = _rate.maximum
      } else if (_rate.minimum !== null && totalAmount < _rate.minimum) {
        totalAmount = _rate.minimum
      }
      const totalTax = _rate.tierGroups.reduce((a, b) => a + (b.taxAmount || 0), 0)
      if (_rate.demand) {
        dispatch(setDemandTestAmounts(totalAmount, _rate.tierGroups))
      } else {
        dispatch(setTestAmounts(totalAmount, _rate.tierGroups))
      }
    } else {
      dispatch(setTestIncomplete(_rate.tierGroups))
    }
  }
}

const calculateIndependentTiers = (units, tierGroups) => {
  tierGroups.forEach(tierGroup => {
    let complete = true
    tierGroup.tiers.forEach(tier => {
      if (tier.input && tier.input.length === 1 && tier.input[0].type === 'units') {
        const input = calculateUnitsInput(units, tier)
        calculateAmountFromInput(tier, input)
      } else {
        complete = false
      }
    })
    if (complete) {
      tierGroup.amount = tierGroup.tiers.reduce((a, b) => a + (b.amount || 0), 0) + (tierGroup.baseCharge ? tierGroup.baseCharge : 0)
    }
  })
}

const calculateDependentTiers = (units, tierGroups) => {
  let totalValueOfDependentTiers = 0
  let newTotalValueOfDependentTiers = 0
  let completedAllTierGroups = true
  let goAgain = true
  do {
    totalValueOfDependentTiers = newTotalValueOfDependentTiers
    newTotalValueOfDependentTiers = 0
    completedAllTierGroups = true
    goAgain = false
    tierGroups.forEach(tierGroup => {
      let complete = true
      tierGroup.tiers.forEach(tier => {
        if (tier.amount === undefined) {
          if (!calculateTierAmount(units, tier, tierGroups)) {
            complete = false
          }
        }
      })
      if (complete) {
        if (tierGroup.amount === undefined || tierGroup.amount === null) {
          goAgain = true
        }
        tierGroup.amount = tierGroup.tiers.reduce((a, b) => a + (b.amount || 0), 0) + (tierGroup.baseCharge ? tierGroup.baseCharge : 0)
        tierGroup.taxAmount = tierGroup.tiers.reduce((a, b) => a + (b.taxAmount || 0), 0) + (tierGroup.baseCharge && tierGroup.taxPercent ? tierGroup.baseCharge * tierGroup.taxPercent / 100 : 0)
        tierGroup.totalAmount = tierGroup.tiers.reduce((a, b) => a + (b.totalAmount || 0), 0) + (tierGroup.baseCharge ? tierGroup.baseCharge : 0) + (tierGroup.baseCharge && tierGroup.taxPercent ? tierGroup.baseCharge * tierGroup.taxPercent / 100 : 0)
        newTotalValueOfDependentTiers += tierGroup.totalAmount
      } else {
        completedAllTierGroups = false
      }
    })
  }
  while (newTotalValueOfDependentTiers > totalValueOfDependentTiers || goAgain)

  return completedAllTierGroups
}

const calculateTierAmount = (units, tier, tierGroups) => {
  tier.inputAmount = tier.inputAmount ? tier.inputAmount : 0
  let complete = true
  tier.input.filter(i => i.amount === undefined).forEach(input => {
    switch (input.type) {
    case 'units':
      input.amount = calculateUnitsInput(units, tier)
      break
    case 'group':
      const _tierGroup = tierGroups.find(tg => tg.name === input.value)
      input.amount = _tierGroup.amount
      break
    case 'tier': {
      const _tierGroup = tierGroups.find(tg => tg.name === input.value.group)
      const _tier = _tierGroup.tiers.find(t => t.name === input.value.tier)

      input.amount = _tier.amount
      break
    }
    case 'rate':
      input.amount = 0
      break
    }
    if (input.amount === undefined) {
      complete = false
    } else {
      tier.inputAmount += input.amount
    }
  })

  if (complete) {
    calculateAmountFromInput(tier)
  } else {
    return false
  }

  return true
}

const calculateUnitsInput = (units, tier) => {
  let inputAmount = units > tier.end && tier.end !== null ?
    tier.end - tier.start
    : tier.start !== null ?
      units - tier.start
      : units
  if (inputAmount < 0) {
    inputAmount = 0
  }
  tier.inputAmount = inputAmount
  return inputAmount
}
const calculateAmountFromInput = tier => {
  tier.amount = 0
  if (tier.fixed) {
    tier.amount = Number(tier.value)
  } else {
    tier.amount = tier.value * tier.inputAmount
  }
  const tax = tier.taxPercent ? Number(tier.taxPercent / 100) : 0
  tier.taxAmount = tier.amount * tax
  tier.totalAmount = tier.amount + tier.taxAmount
}

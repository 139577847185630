import {
  RECEIVE_READINGS,
  REQUEST_UPLOAD_READINGS,
  RECEIVE_UPLOAD_READINGS,
  ERROR_UPLOAD_READINGS,
  READINGS_SET_TAB,
  REQUEST_CHANGE_READINGS_STATUS,
  RECEIVE_CHANGE_READINGS_STATUS,
  ERROR_CHANGE_READINGS_STATUS,
  REQUEST_DELETE_READINGS,
  RECEIVE_DELETE_READINGS,
  TOGGLE_HI_LO_CHECK,
  REQUEST_MARK_READINGS_NOT_BILLED,
  RECEIVE_MARK_READINGS_NOT_BILLED,
  ERROR_MARK_READINGS_NOT_BILLED,
} from '../actions/readingsActions'

import { commonReducer } from './commonReducer'
import { grid, pagination, viewEdit } from './commonStates'

const initialState = {
  ...grid(),
  ...viewEdit(),
  tab: null,
  tabs: {
    0: {
      ...grid(),
      ...pagination(),
    },
    1: {
      ...grid(),
      ...pagination(),
    },
    2: {
      ...grid(),
      ...pagination(),
    },
  },
  ...pagination(),
  isChangingStatus: false,
  isMarkingNotBilled: false,
  errChangingStatus: null,
  errMarkingNotBilled: null,
  hiLoCheckOpen: false,
}

export default (state = initialState, action) => {
  const { readings, err, type, tab, open } = action
  let numPages, p, selectedPageList, filteredReadings

  switch (type) {
  case TOGGLE_HI_LO_CHECK:
    return { ...state, hiLoCheckOpen: open }
  case RECEIVE_READINGS:
    filteredReadings = readings.filter(r => r.statusId === state.selectedStatus)
    numPages = Math.ceil(filteredReadings.length / state.perPage)
    p = state.page[state.tab] && state.page[state.tab] <= numPages ? state.page[state.tab] : 1
    selectedPageList = filteredReadings.slice((p - 1) * state.perPage, p * state.perPage)
    p = Object.assign([], state.page, { [state.tab]: p })
    return Object.assign({}, state, {
      list: readings,
      isFetching: false,
      errFetching: null,
      page: p,
      numPages,
      selectedPageList,
    })

  case REQUEST_UPLOAD_READINGS:
    return Object.assign({}, state, { isCreating: true, errCreating: null })

  case RECEIVE_UPLOAD_READINGS:
    filteredReadings = readings.filter(r => r.statusId === state.selectedStatus)
    numPages = Math.ceil(filteredReadings.length / state.perPage)
    p = state.page[state.tab] && state.page[state.tab] <= numPages ? state.page[state.tab] : 1
    selectedPageList = filteredReadings.slice((p - 1) * state.perPage, p * state.perPage)
    p = Object.assign([], state.page, { [state.tab]: p })
    return Object.assign({}, state, {
      list: readings,
      isCreating: false,
      errCreating: null,
      page: p,
      numPages,
      selectedPageList,
    })

  case ERROR_UPLOAD_READINGS:
    return Object.assign({}, state, { isCreating: false, errCreating: err })

  case REQUEST_CHANGE_READINGS_STATUS:
    return Object.assign({}, state, { isChangingStatus: true, errChangingStatus: null })

  case RECEIVE_CHANGE_READINGS_STATUS:
    return Object.assign({}, state, { isChangingStatus: false, errChangingStatus: null })

  case ERROR_CHANGE_READINGS_STATUS:
    return Object.assign({}, state, { isChangingStatus: false, errChangingStatus: err })

  case REQUEST_MARK_READINGS_NOT_BILLED:
    return Object.assign({}, state, { isMarkingNotBilled: true, errMarkingNotBilled: null })

  case RECEIVE_MARK_READINGS_NOT_BILLED:
    return Object.assign({}, state, { isMarkingNotBilled: false, errMarkingNotBilled: null })

  case ERROR_MARK_READINGS_NOT_BILLED:
    return Object.assign({}, state, { isMarkingNotBilled: false, errMarkingNotBilled: err })

  case READINGS_SET_TAB:
    return Object.assign({}, state, { tab, selected: [] })

  case REQUEST_DELETE_READINGS:
    return Object.assign({}, state, { isDeleting: true, errDeleting: null })

  case RECEIVE_DELETE_READINGS:
    return Object.assign({}, state, { isDeleting: false, errDeleting: err })

  default:
    return commonReducer('READINGS', state, action)
  }
}
